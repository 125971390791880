import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  forwardRef,
  Host,
  Inject,
  Injector,
  OnChanges,
  OnDestroy,
  Optional,
  Renderer2,
  Self,
  SimpleChanges,
  SkipSelf,
  ViewContainerRef
} from '@angular/core';
import {
  AsyncValidator,
  AsyncValidatorFn,
  ControlContainer,
  ControlValueAccessor,
  FormControlName,
  NG_ASYNC_VALIDATORS,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  ValidatorFn
} from "@angular/forms";
import {ShowErrorsComponent} from '../../../shared/components/show-errors/show-errors.component';
import {HelpButtonComponent} from "../../../shared/components/help-button/help-button.component";
import { MarkCompulsoryFieldComponent, MarkCompulsoryFieldComponentTag } from '../../../shared/components/mark-compulsory-field/mark-compulsory-field.component';
import {ComponentFactory} from '@angular/core/src/linker/component_factory';
import {GeecFormControl} from './geec-form-control';

const controlNameBinding: any = {
  provide: ControlContainer,
  useExisting: forwardRef(() => GeecFormControlNameDirective)
};

@Directive({selector: '[formControlName]', providers: [controlNameBinding]})
export class GeecFormControlNameDirective extends FormControlName implements OnDestroy, OnChanges {

  private _showErrors: ComponentRef<ShowErrorsComponent>;

  private _helpButton: ComponentRef<HelpButtonComponent>;

  private _markCompulsoryField: ComponentRef<MarkCompulsoryFieldComponent>;

  constructor(@Optional() @Host() @SkipSelf() parent: ControlContainer,
              @Optional() @Self() @Inject(NG_VALIDATORS) validators: Array<Validator | ValidatorFn>,
              @Optional() @Self() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<AsyncValidator | AsyncValidatorFn>,
              @Optional() @Self() @Inject(NG_VALUE_ACCESSOR) valueAccessors: ControlValueAccessor[],
              private _injector: Injector,
              private _renderer: Renderer2,
              private _viewContainerRef: ViewContainerRef,
              private _componentFactoryResolver: ComponentFactoryResolver) {

    super(parent, validators, asyncValidators, valueAccessors, null);
  }

  private static _getControlContainer(element: HTMLElement): HTMLElement | null {
    return <HTMLElement>element.closest('.control');
  }

  private static _getLabelContainer(element: HTMLElement): HTMLLabelElement | null {
    const parent: HTMLElement = GeecFormControlNameDirective._getControlContainer(element);
    if (parent) {
      const labelElement: HTMLLabelElement = <HTMLLabelElement>parent.previousElementSibling;
      return (labelElement && (labelElement.className.indexOf('label') > -1)) ? labelElement : null;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges.apply(this, arguments);
    if (Reflect.get(this, '_added') && (this.control instanceof GeecFormControl)) {
      this._setUpHelpButton();
      this._setUpShowErrors();
      this._setUpCompulsoryFields();
    }
  }

  ngOnDestroy(): void {
    if (this._showErrors) {
      this._showErrors.destroy();
    }
    if (this._helpButton) {
      this._helpButton.destroy();
    }
    if (this._markCompulsoryField) {
      this._markCompulsoryField.destroy();
    }
    super.ngOnDestroy.apply(this);
  }

  /**
   * Sets up an instance of {@link ShowErrorsComponent} and appends it
   * next to this ElementRef.
   * @private
   */
  private _setUpShowErrors(): void {
    const parent: HTMLElement = GeecFormControlNameDirective._getControlContainer(this._viewContainerRef.element.nativeElement);
    if (parent) {
      const factory = this._componentFactoryResolver.resolveComponentFactory(ShowErrorsComponent);
      this._showErrors = this._viewContainerRef.createComponent(factory);
      this._showErrors.instance.control = this.control;
      parent.appendChild(this._showErrors.location.nativeElement);
    }
  }

  /**
   * Sets up an instance of {@link HelpButtonComponent} and appends it
   * next to this ElementRef.
   * @private
   */
  private _setUpHelpButton(): void {
    const element: HTMLElement = this._viewContainerRef.element.nativeElement;
    let label: HTMLLabelElement = GeecFormControlNameDirective._getLabelContainer(element);

    if (label) {
      const factory: ComponentFactory<HelpButtonComponent> = this._componentFactoryResolver.resolveComponentFactory(HelpButtonComponent);
      this._helpButton = this._viewContainerRef.createComponent(factory);
      this._helpButton.instance.control = this.control;
      label.appendChild(this._helpButton.location.nativeElement);
    }
  }



  /**
   * Sets up an instance of {@link MarkCompulsoryFieldComponent} and appends it
   * next to this ElementRef.
   * @private
   */
  private _setUpCompulsoryFields() {
    const element: HTMLElement = this._viewContainerRef.element.nativeElement;
    let label: HTMLLabelElement = GeecFormControlNameDirective._getLabelContainer(element);

    if (label && label.getElementsByTagName(MarkCompulsoryFieldComponentTag).length === 0) {
      const factory = this._componentFactoryResolver.resolveComponentFactory(MarkCompulsoryFieldComponent);
      this._markCompulsoryField = this._viewContainerRef.createComponent(factory);
      this._markCompulsoryField.instance.control = this.control;
      label.insertBefore(this._markCompulsoryField.location.nativeElement, label.firstChild);
    }
  }
}
