import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { AppConstants } from '../../../app.constants';
import { HttpClientModule } from '@angular/common/http';

@Component({
  selector: 'geec-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  versionFront: string = "1.4.9";

  versionBack: string;

  mailSoporte: string;

  telefonoSoporte: string;

  horarioSoporte: string;

  constructor(private _http: HttpClient) {}

  ngOnInit() {
   /*
   Pendiente si se querá en JCCM
    this.obtenerMailSoporte().subscribe(
      (mail: string) => {
        this.mailSoporte = mail;
      },
      error => {
        console.error('Error al obtener el correo de soporte:', error);
      }
    );

    this.obtenerTelefonoSoporte().subscribe(
      (telefono: string) => {
        this.telefonoSoporte = telefono;
      },
      error => {
        console.error('Error al obtener el teléfono de soporte:', error);
      }
    );

    this.obtenerHorarioSoporte().subscribe(
      (horario: string) => {
        this.horarioSoporte = horario;
      },
      error => {
        console.error('Error al obtener el horario de soporte:', error);
      }
    );*/

    this.getVersionBack().subscribe(
      (versionInfo: any) => {
        console.log(versionInfo);
        this.versionBack = versionInfo.version;
      },
      error => {
        console.error('Error al obtener versión del back:', error);
      }
    );
  }

  obtenerMailSoporte(): Observable<string> {
    return this._http.get<any>(`${AppConstants.urlMailSoporte}`);
  }

  obtenerTelefonoSoporte(): Observable<string> {
    return this._http.get<any>(`${AppConstants.urlTelefonoSoporte}`);
  }

  obtenerHorarioSoporte(): Observable<string> {
    return this._http.get<any>(`${AppConstants.urlHorarioSoporte}`);
  }

  getVersionBack(): Observable<string> {
    return this._http.get<any>(`${AppConstants.urlVersionBack}`);
  }

}
