import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ControllerResponse } from '../../models/forms/controller-response.model';
import { Observable } from 'rxjs/internal/Observable';
import { AppConstants } from '../../../app.constants';
import { LooseObject } from '../../models/forms/reactive-form-validator';
import { CustomUtils } from '../../utils/custom.utils';
import { Response } from '../../models/forms/response.model';


@Injectable()
export class RoleceService {

  constructor(private http: HttpClient) {
    //Esto es intencional
  }

    public certificadoHtml(documentId: string) : Observable<string> {
      let mapString: LooseObject<string> = {};
      mapString["documentId"] = documentId;
      const params: HttpParams = new HttpParams({fromObject: mapString});
      return this.http.get<string>(AppConstants.urlRoleceCertificadoHtml, {params: params, responseType: 'text' as 'json'});
    }

    public crearCertificado(expedientId: number, taskId: string) : Observable<Response<string>> {
      const parsedExpedientId : string = CustomUtils.isDefined(expedientId) ? expedientId.toString() : "";
      let mapString: LooseObject<string> = {};
      mapString["expedientId"] = parsedExpedientId;
      mapString["taskId"] = taskId;
      const params: HttpParams = new HttpParams({fromObject: mapString});
      return this.http.get<Response<string>>(AppConstants.urlRoleceCrearCertificado, {params: params});
    }
}
