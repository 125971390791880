import {DadesExpedientSharedFormModel} from "../dades-expedient/dades-expedient-form.model";
import {PartidaPressupostariaFormModel} from "../../../standalone/components/partides/models/partida-pressupostaria-form.model";
import {OfertaFormModel} from "../../../../dades-basiques/models/dades-basiques-form.model";
import {Remesa} from "../../../standalone/components/remesa/remesa.model";

export enum DadesBasiquesStep {
  DADESCONTRACTE = "Datos del contrato",
  DADESECONOMIQUES = "Datos económicos",
  OFERTES = "Datos de ofertas y adjudicación",
  CONVOCATORIA = "Convocatoria y solicitudes",
  PUBLICACIONS = "Publicaciones",
  PARTIDES_OFERTES = "Redistribución de importes"
}

export class DadesBasiquesSharedFormModel {

  dadesExpedient: DadesExpedientSharedFormModel;
  dadesGenerals: DadesGeneralsSharedFormModel;
  indicadors: IndicadorsSharedFormModel;
  cofinancats: CofinancatsSharedFormModel;
  codis: CodisSharedFormModel;
  terminis: TerminisSharedFormModel;
  imports: ImportsSharedFormModel;
  datesContracte: DatesContracteSharedFormModel;
  dadesExecucio: DadesExecucioSharedFormModel;
  dadesEconomiques: DadesEconomiquesSharedFormModel;
  ofertes: OfertaFormModel[];

  constructor() {
    this.dadesExpedient = new DadesExpedientSharedFormModel();
    this.dadesGenerals = new DadesGeneralsSharedFormModel();
    this.indicadors = new IndicadorsSharedFormModel();
    this.cofinancats = new CofinancatsSharedFormModel();
    this.codis = new CodisSharedFormModel();
    this.terminis = new TerminisSharedFormModel();
    this.imports = new ImportsSharedFormModel();
    this.datesContracte = new DatesContracteSharedFormModel();
    this.dadesExecucio = new DadesExecucioSharedFormModel();
    this.dadesEconomiques = new DadesEconomiquesSharedFormModel();
    this.ofertes = new Array<OfertaFormModel>();
  }

}

export class DadesGeneralsSharedFormModel {

  modalitat: number = null;
  anticipat: boolean = null;
  pluriennal: boolean = null;
  anualidades: number = null;
  anyDesde: number = null;
  anyFins: number = null;
  despesaIngres: number = null;
  codiIntern: string = null;
  estudisDictamens: boolean = null;
  determinacioPreu: number = null;
  gestorExpedient: string = null;
  responsableContracte: string = null;
  correoElectronicoResponsableContrato: string = null;
  nombreResponsableContrato: string = null;
  apellidosResponsableContrato: string = null;
  cargoResponsableContrato: string = null;
  nifResponsable: string = null;
  justificacioNecessitat: string = null;
  serviciosComplementarioObrasMenor: boolean = null;
  catalogoElectronico: boolean = null;
  facturaElectronica: boolean = null;
  pagoElectronico: boolean = null;
  pedidoElectronico: boolean = null;
  admisionVariantes: boolean = null;
  acuerdoContratacionPublica: boolean = null;
  nombreOrganismoReceptor: string = null;
  paisOrganismoReceptor: number = null;
  localidadOrganismoReceptor: number = null;
  cpOrganismoReceptor: string = null;
  direccionOrganismoReceptor: string = null;
  condicionEspecialEjecucion: number = null;

  constructor() {

  }
}

export class CodisSharedFormModel {

  codiCPV: number = null;
  codiProducte: number = null;

  constructor() {

  }

}

export class IndicadorsSharedFormModel {

  teReservaSocial: boolean = null;
  reservaSocial: number = null;
  obraPublica: boolean = null;
  projecteObres: boolean = null;
  contratacionEstrategica: number[] = null;
  teClausulasSociales: boolean = null;
  clausulasSociales: number[] = null;
  teClausulasAmbientales: boolean = null;
  clausulasAmbientales: number[] = null;
  cofinancament: boolean = null;
  clausulesRDI: boolean = null;
  subcontractacio: boolean = null;
  clausulesArbitratge: boolean = null;
  accesExclusiuPlataforma: boolean = null;
  ofertaElectronica: boolean = null;
  contabilitatCentralitzada: boolean = null;
  estanquitat: boolean = null;
  pagamentUnic: boolean = null;
  numCaic: string = null;
  tractamentDadesPersonals: boolean = null;
  admetRecursEspecial: boolean = null;
  requereixInformeRecurrencia: boolean = null;
  vehiculosDirectiva: number = null;

  constructor() {

  }

}

export class CofinancatsSharedFormModel {

  constructor() {

  }

  financamentGlobal: number = null;
  importPrevistLicitacioGlobal: number = null;
  importPrevistAdjudicacioGlobal: number = null;
  cofinancaments: CofinancamentsSharedFormModel[] = new Array<CofinancamentsSharedFormModel>();
}

export class CofinancamentsSharedFormModel {

  constructor() {

  }

  id: number = null;
  vigenciaDesde: number = null;
  vigenciaFins: number = null;
  tipusFons: number = null;
  programa: string = null;
  percent: number = null;
  importPrevistLicitacio: number = null;
  importPrevistAdjudicacio: number = null;
  imputacio: number = null;
  indicador: number = null;
  observacions: string = null;
  concepte: number;
  valor: number;
}

export class ImportsSharedFormModel {

  constructor() {

  }

  importLicitacio: number = null;
  importLicitacioIVA: number = null;
  importAdjudicacio: number = null;
  importAdjudicacioIVA: number = null;
  tipusIvaLicitacio: number = null;
  tipusIvaAdjudicacio: number = null;
  valorEstimat: number = null;
  periodicitat: number = null;
  moneda: number = null;
  importPublicacio: number = null;
  canon: number = null;
  pagamentCanon: number = null;
  aportacionsPubliques: number = null;
  importLicitacioTotal: number = null;
  importLicitacioTotalIva: number = null;
  importAdjudicacioTotal: number = null;
  importAdjudicacioTotalIva: number = null;
  importsUnitat: ImportUnitatSharedFormModel[] = new Array<ImportUnitatSharedFormModel>();
  ingresosUsuarios: number = null;
  ingresosComprador: number = null;
  descripcionValor: string = null;
  valorMaximoRecalculado: number = null;
  nuevoValorEstimado: number = null;
}

export class ImportUnitatSharedFormModel {

  constructor() {

  }

  public expedient: number = null;
  public unitatPromotora: number = null;
  public importLicitacio: number = null;
  public tipusIvaLicitacio: number = null;
  public tipusIvaAdjudicacio: number = null;
  public importLicitacioIva: number = null;
  public importAdjudicacio: number = null;
  public importAdjudicacioIva: number = null;
  public moneda: number = null;
}

export class TerminisSharedFormModel {

  constructor() {

  }

  terminiExecucio: number = null;
  tempsTerminiExecucio: string = null;
  terminiLliurament: number = null;
  tempsTerminiLliurament: string = null;
  terminiConcessio: number = null;
  tempsTerminiConcessio: string = null;
  terminiProjecte: number = null;
  tempsTerminiProjecte: string = null;
  terminiObres: number = null;
  tempsTerminiObres: string = null;
}

export class DatesContracteSharedFormModel {

  constructor() {

  }

  acordOrgan: number = null;
  dataAcordOrgan: Date = new Date();
  dataAdjudicacio: Date = new Date();
  dataFormalitzacio: Date = new Date();
  dataFiscalitzacio: Date = new Date();
}

export class DadesExecucioSharedFormModel {

  constructor() {

  }

  iniciExecucio: Date = new Date();
  fiExecucio: Date = new Date();
  dataComprovacio: Date = new Date();
  directorObra: string = null;
  paisExecucio: number = null;
  provinciaExecucio: number = null;
  paisOrigenProducte: number = null;
  motivoNoAdjudicado: number = null;
}

export class DadesEconomiquesSharedFormModel {

  constructor() {

  }

  remesa: Remesa = null;
  partidesPressupostaries: PartidaPressupostariaFormModel[] = new Array<PartidaPressupostariaFormModel>();
  partidesPeticionaries: PartidaPressupostariaFormModel[] = new Array<PartidaPressupostariaFormModel>();
}
