import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LooseObject } from '../../models/forms/reactive-form-validator';
import { AppConstants } from '../../../app.constants';
import { Response } from '../../models/forms/response.model';
@Injectable()
export class TercerosService {

  constructor(private http: HttpClient) {
    //Esto es intencional
  }

  public existsTercero(expedientId: string) : Observable<Response<string>> {
    let mapString: LooseObject<string> = {};
    mapString["expedientId"] = expedientId;
    const params: HttpParams = new HttpParams({fromObject: mapString});
    return this.http.get<Response<string>>(`${AppConstants.urlTerceros}/exists`, {params: params});
  }
}
