import {TramitacioNode} from "./tramitacio-node";
import {LooseObject} from "../../shared/models/forms/reactive-form-validator";
import {iconMap, TramitacioNodeAction, ValidacioSignatura} from "./model";
import {TramitacioVersioSignatura} from "./tramitacio-versio-signatura";
import {CustomUtils} from "../../shared/utils/custom.utils";

export class TramitacioTaskDocumentVersioNode extends TramitacioNode {

  public contentType: string;
  public codi: string;
  public descripcio: string;
  public nom: string;
  public dataCreacio: Date;
  public dataUltimaModificacio: Date;
  public dataValidacio: Date;
  public visible: boolean;
  public anulat: boolean = false;
  public tipusValidacioSignatura: LooseObject<any>;
  public validacions: Array<ValidacioSignatura> = [];
  public creatPlantilla: boolean;
  public versio: number;
  public urlDocument: string;

  constructor(backendJSON: LooseObject<any>) {
    super(backendJSON);

    this.versio = backendJSON.versio;
    this.urlDocument = backendJSON.urlDocument;
    this.contentType = backendJSON.contentType;
    this.codi = backendJSON.codi;
    this.descripcio = backendJSON.descripcio;
    this.nom = backendJSON.nom;
    this.dataCreacio = backendJSON.dataCreacio;
    this.dataUltimaModificacio = backendJSON.dataUltimaModificacio;
    this.dataValidacio = backendJSON.dataValidacio;
    this.visible = backendJSON.visible;
    this.anulat = backendJSON.anulat;
    this._pushActions();
    
    if (this.anulat) {
      this.styleClass = "document-anulat";
    }

    if (backendJSON.validacions) {
      this.validacions = backendJSON.validacions.validacions;
      this.children = this.validacions.map((validacio: LooseObject<any>) => {
        return new TramitacioVersioSignatura(validacio);
      });
    }

    if (CustomUtils.isArrayNotEmpty(this.validacions)) {
      this.icon = 'fa fa-key';
    } else {
      this.icon = iconMap.get(this.contentType) || 'fa fa-file-text-o';
      this.leaf = true;
    }

    this.selectable = true;
  }

  private _label?: string;

  get label(): string {
    return this._label;
  }

  /**
   * Legacy code from the previous implementation
   * @author albert-mitjavila
   * @param {string} value
   */
  set label(value: string) {

    if (this.dataUltimaModificacio) {
      this.dataUltimaModificacio = new Date(this.dataUltimaModificacio);
    }

    this._label = `v.${this.versio} ${value}`;
  }

  private _pushActions() {
      if (this.codi === 'C_RELI') //Código para certificados de ROLECE
        this.actions.push(
          {key: TramitacioNodeAction.mostrarCertificadoRolece, labelButton: 'Ver Certificado', iconButton: 'fa fa-newspaper-o'},
          {key: TramitacioNodeAction.download, labelButton: 'Descargar Certificado', iconButton: 'fa fa-download'});
      else
        this.actions.push({key: TramitacioNodeAction.version, labelButton: 'Ver', iconButton: 'fa fa-newspaper-o'});
  }

}
