import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, AbstractControlDirective, FormControl } from '@angular/forms';
import { CustomUtils } from '../../utils/custom.utils';

export const MarkCompulsoryFieldComponentTag : string = "geec-mark-compulsory-field"; 

@Component({
  selector: MarkCompulsoryFieldComponentTag,
  template: `<span *ngIf="isRequired" class="is-required">* </span>`,
  styles: [`
      .is-required {
        color: #b11;
      }
    `]
})
export class MarkCompulsoryFieldComponent implements OnInit{

  @Input()
  public control: FormControl;

  public isRequired;

  constructor() { }

  ngOnInit(): void {
    /*
    No hay manera de comprovar si un campo es required a menos que haya un error que lo explicite.
    En los casos que no tiene valor por defecto, el controlador contiene un error con la palabra required.
    Cuando tiene valor por defecto, el controlador no contiene el error por lo que no podemos saber si es required o no. 
    Por eso, quitamos el valor momentaneamente y comprovamos si se genera el error.
    */
    const value = this.control.value;

    this.control.setValue(null);
    this.isRequired = CustomUtils.HOPAID(this.control, "errors") &&
      Object.keys(this.control.errors).some((validator: string) => validator.toLowerCase().includes("required"));
  
    this.control.setValue(value);
  }
}
