export class DadesExpedientSharedFormModel {
  id: number = null;
  numero: string = null;
  titol: string = null;
  objecte: string = null;
  procediment: number = null;
  tipusContracte: number = null;
  unitatsPromotores: number[] = new Array<number>();
  unitatTramitadora: number = null;
  lots: boolean = null;
  totalLots: number = null;
  numeroLot: number = null;
  etiquetaLot: string = null;
  partidas: boolean = null;
  totalPartidas: number = null;
  numeroPartida: number = null;
  etiquetaPartida: string = null;
  teMesa: boolean = null;
  tramitacioSimplificada: number = null;
  subExpedients: string[] = new Array<string>();
  sistemaAdjudicacio: number = null;
  acordMarcTransversal: boolean = null;
  tipusBasatAcordMarcPermesos: number[] = new Array<number>();
  tipusBasatSistemaDinamicAdquisicioPermesos: number[] = new Array<number>();
  lotsAdjudicacio: boolean = null;
  estatLotsAdjudicacio: number = null;
  acordMarcOrigenId: number = null;
  acordMarcOrigen: string = null;
  tipusTramitacio: number = null;
  dataNoOfertasGlic: Date = null;
  publicidad: number = null;
  nuts: string = null;
  directivaAplicacion: string = null;
  modalitat: string = null;
  subtipoContrato: number = null;
  hasCccDerivados: boolean;
  contractacioCentralitzada: boolean;
  urlPlacsp:string = null;

  constructor() {
  }
}
