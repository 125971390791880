import {Component, EventEmitter, Input, Output} from "@angular/core";
import {GeecFormGroup} from "../../../../core/directives/reactive-forms/geec-form-group";
import {SelectItem} from "primeng/api";
import {FormGroup, Validators} from "@angular/forms";
import {AppConstants} from "../../../../app.constants";
import {DadesGeneralsSharedFormValidatorModel} from "../../../models/forms/dades-basiques/dades-basiques-shared-form-validator.model";
import {forkJoin} from "rxjs";
import {catchError} from "rxjs/operators";
import {Router} from "@angular/router";
import {DadaMestraService} from "../../../services/dada-mestra/dada-mestra.service";
import {DadesGeneralsControllerService} from "./dades-generals-controller.service";
import {PoblacioService} from "../../../services/poblacio/poblacio.service";
import {Poblacio} from "../../../models/licitadors/poblacio.model";
import {FormUtils} from "../../../utils/form-utils";
import {AutocompleteSearchEvent} from "../../../interfaces/primeng/autocomplete-search-event";
import {CustomUtils} from "../../../utils/custom.utils";
import { NotificationsService } from "../../../../core/services/notifications/notifications.service";
import { Severity } from "../../../../meses/meses.component";

@Component({
  template: ''
})
export class DadesGeneralsControllerComponent {

  @Input() public parentFormGroup: GeecFormGroup;

  booleanOptions: SelectItem[] = [...AppConstants.booleanOptions];
  anualidadesOptions: SelectItem[] = [...AppConstants.anualidadesOptions];
  tipoPlazoOptions: SelectItem[] = [...AppConstants.tipoPlazoOptions];

  @Input() public templateValidators: DadesGeneralsSharedFormValidatorModel;

  @Output() public triggerValidation: EventEmitter<any> = new EventEmitter();

  @Input() readOnly: boolean;

  paisOptions: SelectItem[] = [];
  selectedCountry: string = '65';
  poblacioOptions: Poblacio[];
  condicionEspecialEjecucionOptions: SelectItem[] = [];

  public anyFins: number;

  constructor(protected router: Router, protected dadaMestraService: DadaMestraService,
              protected service: DadesGeneralsControllerService, protected poblacioService: PoblacioService, protected notificationsService: NotificationsService) {
    this._loadOptions();
  }

  private _loadOptions(): void {
    forkJoin(
      this.service.getPais(),
      this.service.getCondicionEspcialEjecucion()
    ).pipe(
      catchError(() => this.router.navigateByUrl("error"))
    ).subscribe(
      ([pais, condicionEspecialEjecucion]: [SelectItem[], SelectItem[]]) => {
        this.paisOptions = pais;
        this.condicionEspecialEjecucionOptions = condicionEspecialEjecucion;
      });
  }

  get dadesGeneralsForm(): FormGroup {
    return this.parentFormGroup.get("dadesGenerals") as FormGroup;
  }

  public formValidatorEventEmitter(event?: Event): void {
    this.triggerValidation.next(event);
  }

  public isPluriennal(): boolean {
    this.anyFins = this.dadesGeneralsForm.get("anyDesde").value;
    return this.dadesGeneralsForm.get("pluriennal").value;
  }

  public isProrrogaPrevista(): boolean {
    const prorrogaPrevista = this.dadesGeneralsForm.get("prorrogaPrevista").value;
    if (prorrogaPrevista) {
      this.dadesGeneralsForm.get('plazoProrroga').setValidators([Validators.required]);
      this.dadesGeneralsForm.get('tipoPlazoProrroga').setValidators([Validators.required]);
    }
    return prorrogaPrevista;
  }

  public calculateAnyFins(): void {
    this.anyFins = this.dadesGeneralsForm.get("anualidades").value + this.dadesGeneralsForm.get("anyDesde").value;
    this.dadesGeneralsForm.get("anyFins").setValue(this.anyFins);
  }

  filterPoblacions(event: AutocompleteSearchEvent): void {
    FormUtils.autocompleteSearchType(event, this.parentFormGroup.get('dadesGenerals').get('localidadOrganismoReceptor').value,
      (searchText: string) => {
        this._loadPoblacions(searchText);
      });
  }

  private _loadPoblacions(searchText: string): void {
    this.poblacioService.getPoblacionsWithFilter(searchText, {
      page: 0, size: 25, loading: false
    }).subscribe((poblacions: Poblacio[]) => {
        if (!CustomUtils.isUndefinedOrNull(poblacions)) {
          this.poblacioOptions = poblacions;
        }
      },
      () => {
        console.log("Error al cargar las poblaciones");
      }
    );
  }

  buscarResponsable() {
    this.service.getResponsable(
      this.parentFormGroup.get("dadesGenerals").get("correoElectronicoResponsableContrato").value
    ).subscribe(
      value => {
        const dsNombre = value && value.dsNombre ? value.dsNombre : '';
        const dsApellido1 = value && value.dsApellido1 ? value.dsApellido1 : '';
        const dsApellido2 = value && value.dsApellido2 ? value.dsApellido2 : '';
        const dsNomPuesto = value && value.dsNomPuesto ? value.dsNomPuesto : '';
  
        if (!dsNombre && !dsApellido1 && !dsApellido2 && !dsNomPuesto) {
          // Si todos los valores están vacíos, mostrar el mensaje de error
          this.notificationsService.notify(Severity.ERROR, 'No se han encontrado datos del responsable', '');
        }
  
        this.parentFormGroup.get("dadesGenerals").get("nombreResponsableContrato").setValue(`${dsNombre} ${dsApellido1} ${dsApellido2}`.trim());
        this.parentFormGroup.get("dadesGenerals").get("cargoResponsableContrato").setValue(dsNomPuesto);
      },
      error => {
        // Si hay un error, vaciar los campos
        this.parentFormGroup.get("dadesGenerals").get("nombreResponsableContrato").setValue('');
        this.parentFormGroup.get("dadesGenerals").get("cargoResponsableContrato").setValue('');
        this.notificationsService.notify(Severity.ERROR, 'No se han encontrado datos del responsable', '');
      }
    );
  }
}
