
  /**
   * Branch: remotes/origin/develop
   * Last commit: 
   *
   * commit 88f2906783ce4cf4ddc87e66e117b02b8bc7298d
   * Merge: 0b8397f 26f2641
   * Author: Jordi Romagos Figueras <jordi.romagos@dxc.com>
   * Date:   Mon Mar 31 17:34:25 2025 +0200
   *     Merge pull request #119 from JCCM/remove_especial
   *     
   *     remove indicador admite recurso especial
   */
   
   
  export const version = '1.0.0-CEGE-build.31-3-2025 17:36:43';

