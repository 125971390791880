import {CustomUtils} from '../../utils/custom.utils';
import {LooseObject} from "../../models/forms/reactive-form-validator";

export class AutoCompleteConfig {
  page?: number;
  size?: number;
  lang?: string;
  loading?: boolean;
  centrogestorid?: number;
  ejercicio?: number;

  constructor(page: number, size: number, loading: boolean, lang?: string, ejercicio?: number, centrogestorid?: number) {
    this.page = page;
    this.size = size;
    this.loading = loading;
    this.lang = lang;
    this.centrogestorid = centrogestorid;
    this.ejercicio = ejercicio;
  }

  static loadConfig(paramsMap: LooseObject<string> = {}, config: AutoCompleteConfig): LooseObject<string> {
    if (CustomUtils.isDefined(config)) {
      if (CustomUtils.isDefined(config.page)) {
        paramsMap["page"] = JSON.stringify(config.page);
      }
      if (CustomUtils.isDefined(config.size)) {
        paramsMap["size"] = JSON.stringify(config.size);
      }
      if (CustomUtils.isDefined(config.lang)) {
        paramsMap["lang"] = config.lang;
      }
      if (CustomUtils.isDefined(config.centrogestorid)) {
        paramsMap["centrogestorid"] = JSON.stringify(config.centrogestorid);
      }
      if (CustomUtils.isDefined(config.ejercicio)) {
        paramsMap["ejercicio"] = JSON.stringify(config.ejercicio);
      }
      return paramsMap;
    }
  }
}
