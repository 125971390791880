import {Injectable} from "@angular/core";
import {SelectItem} from "primeng/api";
import {DadaMestra, DadaMestraService, DadaMestraType} from "../../../services/dada-mestra/dada-mestra.service";
import {CustomUtils} from "../../../utils/custom.utils";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ResponsableModel} from "../../../../dades-basiques/dades-generals/responsable.model";
import { AppConstants } from "../../../../app.constants";


@Injectable({
  providedIn: 'root'
})
export class DadesGeneralsControllerService {

  constructor(private _dadaMestraService: DadaMestraService, private httpclient: HttpClient) {
  }

  public getPais(): Observable<SelectItem[]> {
    return this._dadaMestraService.getOptions(DadaMestraType.PAIS).pipe(map((data: DadaMestra[]) => {
      return CustomUtils.toSelectItemArray(data);
    }, (err: any) => console.error(err)));
  }

  public getCondicionEspcialEjecucion(): Observable<SelectItem[]> {
    return this._dadaMestraService.getOptions(DadaMestraType.CONDICION_ESPECIAL_EJECUCION).pipe(map((data: DadaMestra[]) => {
      return CustomUtils.toSelectItemArray(data);
    }, (err: any) => console.error(err)));
  }

public getResponsable(email:string): Observable<ResponsableModel> {
  const params = new HttpParams({fromObject: {email: email}});
return this.httpclient.get<ResponsableModel>(`${AppConstants.urlGetResponsable}`,{params:params})
  }
}
